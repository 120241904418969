<template>
  <v-card
    id="recurring-order-form"
    min-height="350"
    :class="{'elevation-0': !fromVideo}"
  >
    <blocked-times-tab-general
      :data-params="generalData"
      :tab="tab"
      :option="option"
      :from-video="fromVideo"
      :fields="fieldsCheckboxOptions"
      :minutes="computedMinutes"
      :end-time-minutes="endTimeMinutes"
      :is-open="isOpen"
      @updateEndTimes="filterDurations"
      @changeTab="changeTab"
      @cancel="cancelAction"
      @save="saveAction"
    ></blocked-times-tab-general>
  </v-card>
</template>

<script>
/* eslint-disable import/no-cycle */
import {
  mdiAccountOutline,
  mdiTableArrowLeft,
} from '@mdi/js'
import {
  onMounted, reactive, ref, computed, watch,
} from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'
import { convertHour12To24, convertHour24To12, sumMinutesOptions } from '@core/utils/reservation'
import { error, success } from '@core/utils/toasted'
import moment from 'moment'
import {
  createBlockedTime,
  editBlockedTime,
  getBlockedTime,
  getFieldsByFacility,
  createRecurringEvent,
  getReservationAvailabilityTime,
} from '@api'
import useSelectOptions from '@core/utils/useSelectOptions'
import useBlockedTimesList from './useBlockedTimesList'

// components
import BlockedTimesTabGeneral from './BlockedTimesTabGeneral.vue'

export default {
  components: {
    BlockedTimesTabGeneral,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    option: {
      type: Number,
      required: false,
      default: 2,
    },
    fromVideo: {
      type: Boolean,
      required: false,
      default: true,
    },
    dataParams: {
      type: Object,
      required: false,
      default: () => {},
    },
    blockedTimeId: {
      type: Number,
      required: false,
      default: null,
    },
    date: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { route, router } = useRouter()
    const { configFacility, configOrganization } = useSelectOptions()
    const {
      weekdayOptions,
      userData,
    } = useBlockedTimesList()

    // const option = ref(1)
    const tab = ref(0)
    const pageData = ref()
    const filterData = ref()
    const dataId = ref(null)
    const nowDate = ref(
      new Date(new Date()).toLocaleString('sv-SE', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    )
    const generalData = ref({
      facility_id: null,
      fields_ids: [],
      start_time: null,
      end_time: null,
      status: 'A',
      blocked_date: nowDate.value,
      blocked_end_date: null,
      make_recurring: 'N',
      frequency: null,
      type: null,
    })
    const postData = ref({
      facility_id: null,
      fields_ids: [],
      start_time: null,
      end_time: null,
      status: 'A',
      blocked_date: nowDate.value,
      blocked_end_date: null,
      make_recurring: 'N',
      frequency: null,
      type: null,
    })
    const putData = ref({
      facility_id: null,
      fields_ids: [],
      start_time: null,
      end_time: null,
      status: 'A',
      blocked_date: nowDate.value,
      blocked_end_date: null,
      make_recurring: 'N',
      frequency: null,
      type: null,
    })
    const dataComputed = computed(() => props.dataParams)
    const computedIsOpen = computed(() => props.isOpen)
    const computedMinutes = computed(() => sumMinutesOptions('00:00', '23:59'))
    const typeOptions = reactive({
      video: 'video',
      booking: 'booking',
    })
    const tabs = ref([
      {
        title: 'General',
        icon: mdiAccountOutline,
        disabled: false,
        hide: false,
      },
    ])
    const fieldsCheckboxOptions = ref([])
    const timerAvaility = ref(null)

    // const minuteWarnText = ref(null)
    const endTimeMinutes = ref([])

    const changeTab = (tabNumber, validForm) => {
      switch (tabNumber) {
        case 1:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[1].disabled = false
          }
          break
        case 2:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[2].disabled = false
          }
          break
        default:
          break
      }

      tab.value = tabNumber
    }

    const cancelAction = () => {
      tab.value = 0
      generalData.value = {
        facility_id: null,
        fields_ids: [],
        start_time: null,
        end_time: null,
        status: 'A',
        blocked_date: nowDate.value,
        blocked_end_date: null,
        make_recurring: 'N',
        frequency: null,
        type: null,
      }
    }

    const saveAction = async data => {
      let resp = null
      if (props.option === 1) {
        postData.value = Object.assign(postData.value, {
          facility_id: configFacility.value,
          fields_ids: data.fields_ids,
          start_time: convertHour12To24(data.start_time),
          end_time: convertHour12To24(data.end_time),
          status: data.status,
          blocked_date: data.blocked_date,
          blocked_end_date: data.blocked_end_date,
          make_recurring: data.make_recurring,
          frequency: data.frequency,
          type: props.fromVideo ? typeOptions.video : typeOptions.booking,
        })
        if (data.make_recurring === 'Y') {
          postData.value.group_id = configOrganization.value
          postData.value.user_console_id = userData.value.username
          postData.value.start_date = data.blocked_date
          postData.value.end_date = data.blocked_end_date
          postData.value.event_type = props.fromVideo ? typeOptions.video : typeOptions.bookings

          resp = await createRecurringEvent(postData.value)
        } else resp = await createBlockedTime(postData.value)
      }

      if (props.option === 3) {
        // data.fields_ids,
        putData.value = Object.assign(putData.value, {
          facility_id: configFacility.value,
          field_id: data.field_id,
          start_time: convertHour12To24(data.start_time),
          end_time: convertHour12To24(data.end_time),
          status: data.status,
          blocked_date: data.blocked_date,
          blocked_end_date: data.blocked_end_date,
          make_recurring: data.make_recurring,
          frequency: data.frequency,
          type: props.fromVideo ? typeOptions.video : typeOptions.booking,
        })
        resp = await editBlockedTime(putData.value, data.id)
      }

      if (resp.ok) {
        cancelAction()

        emit('refresh')
        success(resp.message)
      } else {
        error(resp.message.text)
      }
    }

    const goBack = () => {
      // name: 'views-blocked-times-list',
      router.replace({
        name: 'views-video-view',
        params: {
          pageParams: pageData.value,
          filterParams: filterData.value,
        },
      })
    }

    const filterDurations = async () => {
      if (timerAvaility.value) clearTimeout(timerAvaility.value)
      timerAvaility.value = setTimeout(async () => {
        const availityPromises = []
        if (generalData.value.fields_ids.length === 0) return
        generalData.value.fields_ids.forEach(e => {
          availityPromises.push(getReservationAvailabilityTime(e, generalData.value.blocked_date, convertHour12To24(generalData.value.start_time)))
        })
        const responses = await Promise.all(availityPromises)
        if (responses.every(e => e.ok && e.data)) {
          let minLength = 1000000
          let minObject = null

          responses.forEach(e => {
            if (e.data.length < minLength) {
              minLength = e.data.length
              minObject = e
            }
          })

          endTimeMinutes.value = minObject.data.map(e => ({
            ...e,
            text: convertHour24To12(e.end_time),
            value: e.reservation_time,
          }))
          generalData.value.end_time = endTimeMinutes.value.at(0).text
        } else {
          generalData.value.make_recurring = 'N'
          endTimeMinutes.value = []
          generalData.value.end_time = null
          generalData.value.frequency = null
        }
      }, 300)
    }

    const fetchFields = async () => {
      if (configFacility.value) {
        const fields = await getFieldsByFacility(configFacility.value)
        fieldsCheckboxOptions.value = fields.data.map(e => ({
          ...e,
          title: e.name,
          subtitle: e.sport ? e.sport.name : '',
          value: e.id,
          desc: null,
          checked: false,
        }))
      } else fieldsCheckboxOptions.value = []
    }

    watch([configOrganization, configFacility], async () => {
      // goBack()
      await fetchFields(configFacility.value)
    })

    const initForm = async () => {
      pageData.value = route.value.params.pageParams
      filterData.value = route.value.params.filterParams

      // dataId.value = route.value.params.id
      dataId.value = props.blockedTimeId

      if (dataId.value) {
        const resp = await getBlockedTime(dataId.value)
        if (resp.ok) {
          generalData.value = resp.data
          generalData.value.start_time = convertHour24To12(generalData.value.start_time)
          generalData.value.end_time = convertHour24To12(generalData.value.end_time)
          generalData.value.fields_ids = [generalData.value.field_id]
          if (props.option === 3) generalData.value.fields_id = generalData.value.field_id
        } else goBack()
      } else {
        // props.option = 1

        if (props.date) {
          generalData.value.blocked_date = props.date
          generalData.value.blocked_end_date = moment(props.date).add(1, 'days').format('YYYY-MM-DD')
        } else {
          generalData.value.blocked_date = nowDate.value
          generalData.value.blocked_end_date = moment(nowDate.value).add(1, 'days').format('YYYY-MM-DD')
        }
        generalData.value.status = 'A'
        if (dataComputed.value && dataComputed.value.category) {
          generalData.value.start_time = dataComputed.value.start_time
          const finded = fieldsCheckboxOptions.value.find(e => e.name === dataComputed.value.category)
          if (finded) {
            generalData.value.fields_ids = [finded.id]
          }
        } else {
          generalData.value.start_time = computedMinutes.value.at(0).value
        }
      }

      if (props.option !== 1) {
        tabs.value.forEach(e => {
          e.disabled = false
        })

        tabs.value.splice(1, 1)
      }
    }

    watch([computedIsOpen], async () => {
      if (computedIsOpen.value) {
        await initForm()
      } else {
        cancelAction()
      }
    })

    onMounted(async () => {
      setTimeout(async () => {
        await fetchFields(configFacility.value)
        await initForm()
        if (!props.fromVideo) await filterDurations()
      }, 200)
    })

    return {
      // option,
      tab,
      tabs,
      userData,
      pageData,
      filterData,
      dataId,
      generalData,
      postData,
      putData,
      weekdayOptions,
      dataComputed,
      computedIsOpen,
      fieldsCheckboxOptions,
      computedMinutes,
      filterDurations,
      endTimeMinutes,

      changeTab,
      saveAction,
      cancelAction,
      goBack,

      // i18n
      t,

      icons: {
        mdiAccountOutline,
        mdiTableArrowLeft,
      },
    }
  },
}
</script>
